<template>
  <div id="productIdCard">
    <el-dialog
      :title="productIdCardFormTitle"
      width="360px"
      :visible.sync="productIdCardDialogVisible"
      :close-on-click-modal="false"
      @close="productIdCardDialogClose"
    >
      <el-form
        ref="productIdCardFormRef"
        :model="productIdCardForm"
        :rules="productIdCardFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="物料/产品名称" prop="productName">
          <el-input v-model="productIdCardForm.productName" placeholder="请输入物料/产品名称" clearable />
        </el-form-item>
        <el-form-item label="批号" prop="batchNum">
          <el-input v-model="productIdCardForm.batchNum" placeholder="请输入批号" clearable />
        </el-form-item>
        <el-form-item label="规格/型号" prop="spec">
          <el-input v-model="productIdCardForm.spec" placeholder="请输入规格/型号" clearable />
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="productIdCardForm.remarks" placeholder="请输入备注" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="productIdCardDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productIdCardFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料/产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入物料/产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="productIdCardPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="物料/产品名称" />
      <el-table-column prop="batchNum" label="批号" />
      <el-table-column prop="spec" label="规格/型号" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="productIdCardPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProductIdCard, deleteProductIdCard, updateProductIdCard, selectProductIdCardInfo, selectProductIdCardList } from '@/api/storage/productIdCard'

export default {
  data () {
    return {
      productIdCardDialogVisible: false,
      productIdCardFormTitle: '',
      productIdCardForm: {
        id: '',
        productName: '',
        batchNum: '',
        spec: '',
        remarks: ''
      },
      productIdCardFormRules: {
        productName: [{ required: true, message: '物料/产品名称不能为空', trigger: ['blur', 'change']}]
      },
      productIdCardPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectProductIdCardList(this.searchForm).then(res => {
      this.productIdCardPage = res
    })
  },
  methods: {
    productIdCardDialogClose () {
      this.$refs.productIdCardFormRef.resetFields()
    },
    productIdCardFormSubmit () {
      if (this.productIdCardFormTitle === '物料产品标志卡详情') {
        this.productIdCardDialogVisible = false
        return
      }
      this.$refs.productIdCardFormRef.validate(async valid => {
        if (valid) {
          if (this.productIdCardFormTitle === '新增物料产品标志卡') {
            await addProductIdCard(this.productIdCardForm)
          } else if (this.productIdCardFormTitle === '修改物料产品标志卡') {
            await updateProductIdCard(this.productIdCardForm)
          }
          this.productIdCardPage = await selectProductIdCardList(this.searchForm)
          this.productIdCardDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.productIdCardFormTitle = '新增物料产品标志卡'
      this.productIdCardDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProductIdCard(row.id)
        if (this.productIdCardPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.productIdCardPage = await selectProductIdCardList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.productIdCardFormTitle = '修改物料产品标志卡'
      this.productIdCardDialogVisible = true
      this.selectProductIdCardInfoById(row.id)
    },
    handleInfo (index, row) {
      this.productIdCardFormTitle = '物料产品标志卡详情'
      this.productIdCardDialogVisible = true
      this.selectProductIdCardInfoById(row.id)
    },
    selectProductIdCardInfoById (id) {
      selectProductIdCardInfo(id).then(res => {
        this.productIdCardForm.id = res.id
        this.productIdCardForm.productName = res.productName
        this.productIdCardForm.batchNum = res.batchNum
        this.productIdCardForm.spec = res.spec
        this.productIdCardForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProductIdCardList(this.searchForm).then(res => {
        this.productIdCardPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProductIdCardList(this.searchForm).then(res => {
        this.productIdCardPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProductIdCardList(this.searchForm).then(res => {
        this.productIdCardPage = res
      })
    }
  }
}
</script>

<style>
</style>
