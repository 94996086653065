import axios from '@/common/axios'
import qs from 'qs'

export function addProductIdCard (data) {
  return axios({
    method: 'post',
    url: '/storage/productIdCard/add',
    data: qs.stringify(data)
  })
}

export function deleteProductIdCard (id) {
  return axios({
    method: 'delete',
    url: '/storage/productIdCard/delete/' + id
  })
}

export function updateProductIdCard (data) {
  return axios({
    method: 'put',
    url: '/storage/productIdCard/update',
    data: qs.stringify(data)
  })
}

export function selectProductIdCardInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/productIdCard/info/' + id
  })
}

export function selectProductIdCardList (query) {
  return axios({
    method: 'get',
    url: '/storage/productIdCard/list',
    params: query
  })
}
